<!--
 * @Description:统计分析 整体收益分析 overallIncomeAnalysis
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2023-03-18 10:16:12
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <!-- <el-col :span="6">
            <el-form-item label-width="110px"
                          label="停车场">
              <el-select v-model="searchForm.parkId"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in parkNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label-width="90px"
                          label="统计日期">
              <el-date-picker v-model="statisticDate"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="onSubmit">查 询</el-button>
        <el-button type="info"
                   style="margin-left: 17px"
                   @click="resetForm">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>整体收益分析</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table ref="tableRef"
                  :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig="tableConfig"
                  :paginationConfig="paginationConfig"
                  @getList="getList">
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15 },
      tableColumnList: [
        { prop: "date", label: "日期" },
        { prop: "receivableMoney", label: "应收金额" },
        { prop: "receivedMoney", label: "实收金额" },
        { prop: "realtimeReceivedMoney", label: "实时收入金额" },
        { prop: "supplementMoney", label: "补缴" },
        { prop: "refundMoney", label: "退款金额" },
        { prop: "arrearsMoney", label: "欠费金额" },
        { prop: "financialArrivalMoney", label: "财务到账" },
        { prop: "wechatRevenue", label: "微信" },
        { prop: "aliPayRevenue", label: "支付宝" },
        { prop: "refundMoney", label: "差额" },
        { prop: "realtimePaymentRate", label: "缴费率" },
        { prop: "paymentRate", label: "综合缴费率" },
        { prop: "utilizationRate", label: "车流量" },
        { prop: "paymentCarCount", label: "缴费订单" },
        { prop: "arrearsOrderCount", label: "欠费订单" },
        { prop: "singleBerthIncome", label: "单车位产值" },
        { prop: "parkDurationForDay", label: "停车分钟数" },
        { prop: "parkDuration", label: "停车时长" },
        { prop: "turnoverRate", label: "周转率" },
        { prop: "parkSpaceNumber", label: "车位数" },
        { prop: "positionNumber", label: "岗位数" },
      ],
      tableList: { list: [] },
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      parkNameList: [], // 停车场
      statisticDate: [], // 统计日期
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList();
    this.queryParkNameList();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导出按钮
    exportFile () {
      this.$axios.post(this.$downloadOrderBaseUrl + "operationStatistics/exportEarningAnalysis", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "整体收益分析.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 初始化渲染
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$statisticAnalysisManagement.getEarningAnalysisList(this.searchForm).then((res) => {
        this.tableList.list = res.resultEntity.list;
        this.paginationConfig.total = res.resultEntity.total;
      });
    },
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    onSubmit () {
      if (this.statisticDate) {
        this.searchForm.startDate = this.statisticDate[0]
        this.searchForm.endDate = this.statisticDate[1]
      } else {
        this.searchForm.startDate = ''
        this.searchForm.endDate = ''
      }
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.statisticDate = []
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList();
    },
    //获取停车场名称
    queryParkNameList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park'
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 110px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>